/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.12.3-v202503192210-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqDatafilesApiClass {
 
   constructor() {}

  /**
   * @summary Archive a datafile and all of its signals
   * @param {string} id - ID of the datafile to archive
   */
  public archiveDatafile(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/datafiles/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Create a datafile
   */
  public createDatafile(
    body: models.DatafileInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/datafiles`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatafileOutputV1>;
  }

  /**
   * @summary Get a Datafile
   * @param {string} id - ID of the datafile to retrieve
   */
  public getDatafile(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datafiles/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatafileOutputV1>;
  }

  /**
   * @summary Get a collection of datafiles
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getDatafiles(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datafiles`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get a list of the items hosted by this datafile
   * @param {string} id - ID of the datafile
   * @param {string} [dataId] - The dataId for a specific hosted item
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getItemsHostedByDatafile(
    {
      id,
      dataId,
      offset,
      limit
    } : {
      id: string,
      dataId?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datafiles/${encodeURIComponent(String(id))}/items`,

      params: omitBy({
        ['dataId']: dataId,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Import a CSV file that can be used to create or update a Datafile
   * @param {Blob} [file]
   */
  public importCSV(
    {
      file
    } : {
      file?: File
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    const formData = new FormData();
    formData.append('file', file as File, file?.name);

    return getAxiosInstance().post(
      APPSERVER_API_PREFIX + '/datafiles/csv',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...extraHttpRequestConfig,
    }) as AxiosPromise<models.CsvDatafileOutputV1>;
  }

  /**
   * @summary Update a datafile
   * @param {string} id - ID of the datafile to retrieve
   */
  public updateDatafile(
    body: models.DatafileInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/datafiles/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatafileOutputV1>;
  }

}


export const sqDatafilesApi = new sqDatafilesApiClass();
