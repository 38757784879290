import { SeeqNames } from '@/main/app.constants.seeqnames';
import { BatchActionEnum } from '@/sdk/model/TableDefinitionInputV1';
import { AgGridModules } from '@/core/tableUtilities/tableUtilities.types';
import type { ColDef, DataTypeDefinition, SizeColumnsToContentStrategy } from '@ag-grid-community/core';
import _ from 'lodash';
import type { AnyProperty } from '@/utilities.types';
import { NULL_PLACEHOLDER } from '@/tableBuilder/tableBuilder.constants';
import { ColumnDefinitionInputV1 } from '@/sdk';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionOutputV1';

export const HIDDEN_COLUMNS: string[] = [
  SeeqNames.MaterializedTables.ItemIdColumn,
  SeeqNames.MaterializedTables.DatumIdColumn,
];

export const ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.ItemIdColumn,
  columnType: ColumnTypeEnum.UUID,
  columnRules: [{ eventProperty: { propertyName: 'id' } }],
  sortIndex: 0,
  sortAscending: true,
};

export const DATUM_ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.DatumIdColumn,
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [{ eventProperty: { propertyName: '' } }],
};

export const DEFAULT_COLUMN_DEFINITION_INPUTS = [ID_COLUMN, DATUM_ID_COLUMN];

export const DEFAULT_PROPERTIES_FOR_ALL_ITEM_COLUMNS: string[] = [
  SeeqNames.Properties.DatasourceClass,
  SeeqNames.Properties.Name,
];

export const batchActionOptions = [
  {
    value: BatchActionEnum.UPDATEEXISTINGINSERTNEWCLEANUP,
    label: 'SCALING.BATCH_ACTION.OPTIONS.UPDATE_EXISTING_INSERT_NEW_CLEANUP',
  },
  { value: BatchActionEnum.UPDATEEXISTINGINSERTNEW, label: 'SCALING.BATCH_ACTION.OPTIONS.UPDATE_EXISTING_INSERT_NEW' },
  { value: BatchActionEnum.ONLYUPDATEEXISTING, label: 'SCALING.BATCH_ACTION.OPTIONS.ONLY_UPDATE_EXISTING' },
  { value: BatchActionEnum.ONLYINSERTNEW, label: 'SCALING.BATCH_ACTION.OPTIONS.ONLY_INSERT_NEW' },
];

export const MAX_ROW_COUNT = 500000;

export const MODULES = [
  AgGridModules.MenuModule,
  AgGridModules.ClipboardModule,
  AgGridModules.RangeSelectionModule,
  AgGridModules.ColumnsToolPanelModule,
];

export const GARBAGE_OBJECT_CHAR = '὇B';

export const DEFAULT_COL_DEF: ColDef = {
  menuTabs: ['generalMenuTab'],
  suppressHeaderMenuButton: false,
  headerComponentParams: { menuIcon: 'fa-bars' },
  valueFormatter: (params) => {
    if (_.isNil(params.value)) {
      return '-';
    }

    return params.value;
  },
};

export const AUTO_SIZE_STRATEGY: SizeColumnsToContentStrategy = {
  type: 'fitCellContents',
};

export const DATA_TYPE_DEFINITIONS: AnyProperty<DataTypeDefinition> = {
  numericWithUom: {
    extendsDataType: 'object',
    baseDataType: 'object',
    valueFormatter: (params: any) =>
      _.isNil(params.value?.value) || params.value.value === GARBAGE_OBJECT_CHAR
        ? NULL_PLACEHOLDER
        : `${params.value?.value}${params.value?.uom ?? ''}`,
  },
  uuid: {
    extendsDataType: 'object',
    baseDataType: 'object',
    valueFormatter: (params: any) => {
      if (params.colDef?.propertyToDisplay && params.value?.fetchedProperties) {
        return params.value.fetchedProperties[params.colDef.propertyToDisplay] === GARBAGE_OBJECT_CHAR
          ? NULL_PLACEHOLDER
          : params.value.fetchedProperties[params.colDef.propertyToDisplay];
      }

      return params.value?.uuid ?? NULL_PLACEHOLDER;
    },
  },
  text: {
    extendsDataType: 'object',
    baseDataType: 'object',
    valueFormatter: (params: any) =>
      params.value?.value && params.value.value !== GARBAGE_OBJECT_CHAR ? params.value.value : NULL_PLACEHOLDER,
  },
  boolean: {
    extendsDataType: 'object',
    baseDataType: 'object',
    valueFormatter: (params: any) => {
      if (params.value?.value) {
        return params.value.value === GARBAGE_OBJECT_CHAR ? NULL_PLACEHOLDER : 'true';
      }

      return 'false';
    },
  },
};
